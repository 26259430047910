import { TLinks } from 'containers/layout/types';

export const ABOUT_US_LINK_LIST: TLinks[] = [
  {
    label: 'Our History',
    route: 'about-us#our-history',
  },
  {
    label: 'Team',
    route: 'about-us#team',
  },
  {
    label: 'Proud Members of',
    route: 'about-us#proud-members',
  },
  {
    label: 'Our Partners',
    route: 'about-us#our-partners',
  },
];

export const HOME_FOOTER_LINK_LIST: TLinks[] = [
  {
    label: 'Core Values',
    route: '/#core-values',
  },
  {
    label: 'Site Map',
    route: '/#site-map',
  },
];

export const ABOUT_US_FOOTER_LINK_LIST: TLinks[] = [
  {
    label: 'History',
    route: 'about-us#our-history',
  },
  {
    label: 'Team',
    route: 'about-us#team',
  },
  {
    label: 'Proud Members of',
    route: 'about-us#proud-members',
  },
  {
    label: 'Our Partners',
    route: 'about-us#our-partners',
  },
];

export const SAFETY_FOOTER_LINK_LIST: TLinks[] = [
  {
    label: 'Our Safety Doctrine',
    route: 'safety#our-safety-doctrine',
  },
  {
    label: 'TRUST Program',
    route: 'safety#trust-program',
  },
  {
    label: 'Safety eBook',
    route: 'safety#safety-ebook',
  },
];

export const NEWS_FOOTER_LINK_LIST: TLinks[] = [
  {
    label: 'All News',
    route: '/news',
  },
];

export const EXPERTISE_FOOTER_LINK_LIST: TLinks[] = [
  {
    label: 'Engineering',
    route: '/expertise/engineering',
  },
  {
    label: 'Construction',
    route: '/expertise/construction',
  },
  {
    label: 'Communication',
    route: '/expertise/communication/wireline',
  },
  {
    label: 'Energy',
    route: '/expertise/energy',
  },
  {
    label: 'Technology Deployment',
    route: '/expertise/technology-deployment/gpm',
  },
];

export const PROJECTS_FOOTER_LINK_LIST: TLinks[] = [
  {
    label: 'Background',
    route: '/projects#background',
  },
  {
    label: 'Services delivered',
    route: '/projects#mapSection',
  },
  {
    label: 'Case studies',
    route: 'projects#case-studies',
  },
];

export const CAREERS_FOOTER_LINK_LIST: TLinks[] = [
  {
    label: 'Why Utilities One',
    route: '/careers#whyUtilitiesOne',
  },
  {
    label: 'Back Office Careers',
    route: '/careers/jobs?category=334918d9-f4ae-4da1-9a1b-d4001f90e37e',
  },
  {
    label: 'Engineering Careers',
    route: '/careers/jobs?category=162ce98e-902e-4879-b0d3-7101be881889',
  },
  {
    label: 'Construction Careers',
    route: '/careers/jobs?category=60bb2ef0-738c-48f6-857a-3e422b6ad9af',
  },
  {
    label: 'Wireless Careers',
    route: '/careers/jobs?category=671e83e2-979a-467a-be12-76619ff4ff89',
  },
  {
    label: 'Energy Careers',
    route: '/careers/jobs?category=83a69398-dced-4e77-8313-bfd9be6d6947',
  },
  {
    label: 'Subcontracting Opportunities',
    route: '/subcontracting',
  },
];

export const CONTACT_FOOTER_LINK_LIST: TLinks[] = [
  {
    label: 'General Contact Information',
    route: '/contact-us',
  },
  {
    label: 'Our Offices Locations',
    route: '/contact-us#ourOfficesLocations',
  },
  {
    label: 'Contact Form',
    route: '/contact-us',
  },
];

export const CAREERS_LINK_LIST: TLinks[] = [
  {
    label: 'Open positions',
    route: '/careers/jobs',
  },
  {
    label: 'Subcontracting Opportunities',
    route: '/subcontracting',
  },
];
