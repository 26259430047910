import React, { FC } from 'react';
import { Box, Container, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import {
  ArrowIcon,
  FacebookIcon,
  FooterLogo,
  LinkedinIcon,
  LocationIcon,
  PhoneIcon,
  PrivacyPolicy,
  TermsOfUse,
} from 'svgs/footer';
import { routesFooter } from 'config/routes/routes';
import Link from 'next/link';
import { INSTAGRAM_LINK, LOCATION_ADDRESS } from 'config';
import { InstagramDarkIcon } from '../../../svgs';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    padding: theme.spacing(6, 0),
    position: 'relative',
  },
  title: {
    color: theme.palette.common.white,
    textTransform: 'uppercase',
    marginBottom: theme.spacing(1),
    cursor: 'pointer',
    fontWeight: 500,
  },
  link: {
    color: '#7E808F',
    margin: theme.spacing(2, 0),
    willChange: 'color',
    transition: 'color 0.2s ease',
    cursor: 'pointer',
    fontWeight: 500,
    '&:hover': {
      color: theme.palette.common.white,
    },
  },
  divider: {
    marginTop: theme.spacing(6),
    width: '100%',
    height: 1,
    backgroundColor: theme.palette.common.white,
    opacity: 0.2,
  },
  info: {
    marginTop: theme.spacing(5),
    display: 'flex',
    justifyContent: 'space-between',
  },
  infoText: {
    fontWeight: 500,
    color: theme.palette.common.white,
    marginLeft: theme.spacing(1),
  },
  companyName: {
    color: theme.palette.primary.main,
    textAlign: 'center',
  },
  linksContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  arrowButton: {
    width: 40,
    height: 40,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 50,
    position: 'absolute',
    top: -20,
    right: 40,
    transition: 'background-color 0.3s ease',
    willChange: 'background-color',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.secondary.contrastText,
    },
  },
}));

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};

const DesktopFooter: FC = () => {
  const currentYear = new Date().getFullYear();
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Box className={classes.arrowButton} onClick={scrollToTop} display="flex" justifyContent="center" alignItems="center">
        <ArrowIcon />
      </Box>
      <Container>
        <Grid container>
          <Grid xs={12} sm={12} md={12} lg={12} item>
            <Box className={classes.linksContainer}>
              {routesFooter.map(({ route, label, links }, index) => (
                <Box key={index}>
                  <Link href={route} passHref>
                    <a>
                      <Typography variant="body1" className={classes.title}>
                        {label}
                      </Typography>
                    </a>
                  </Link>
                  {links.map(({ label, links }, index) => (
                    <Box key={index}>
                      {links.map(({ label, route }) => (
                        <Link href={route} key={route + label} passHref>
                          <a>
                            <Typography variant="body2" className={classes.link}>
                              {label}
                            </Typography>
                          </a>
                        </Link>
                      ))}
                    </Box>
                  ))}
                </Box>
              ))}
            </Box>
            <Box className={classes.divider} />
            <Box className={classes.info} alignItems="center">
              <Link href={'/'}>
                <a>
                  <FooterLogo />
                </a>
              </Link>
              <Box>
                <Box display="flex">
                  <LocationIcon />
                  <Typography variant="body1" className={classes.infoText}>
                    {LOCATION_ADDRESS}
                  </Typography>
                </Box>
                <Box display="flex" mt={3}>
                  <PhoneIcon />
                  <a href="tel:+2013088253">
                    <Typography variant="body1" className={classes.infoText}>
                      (201) 308-8253
                    </Typography>
                  </a>
                </Box>
              </Box>
              <Box>
                <Box display="flex">
                  <TermsOfUse />
                  <Link href="/terms-of-use">
                    <a>
                      <Typography variant="body1" className={classes.infoText}>
                        Terms of use
                      </Typography>
                    </a>
                  </Link>
                </Box>
                <Box display="flex" mt={3}>
                  <PrivacyPolicy />
                  <Link href="/privacy-policy">
                    <a>
                      <Typography variant="body1" className={classes.infoText}>
                        Privacy policy
                      </Typography>
                    </a>
                  </Link>
                </Box>
              </Box>
              <Box>
                <Link href={'https://www.facebook.com/UtilitiesOne/?view_public_for=1625716964118130'}>
                  <a>
                    <Box display="flex" alignItems="center" marginRight="24px">
                      <FacebookIcon />
                      <Typography variant="body1" className={classes.infoText}>
                        Facebook
                      </Typography>
                    </Box>
                  </a>
                </Link>
                <Box mt={3}>
                  <Link href={'https://www.linkedin.com/company/utilitiesone/?viewAsMember=true'}>
                    <a>
                      <Box display="flex" alignItems="center">
                        <LinkedinIcon />
                        <Typography variant="body1" className={classes.infoText}>
                          LinkedIn
                        </Typography>
                      </Box>
                    </a>
                  </Link>
                </Box>
                <Box mt={3}>
                  <Link href={INSTAGRAM_LINK}>
                    <a>
                      <Box display="flex" alignItems="center">
                        <InstagramDarkIcon />
                        <Typography variant="body1" className={classes.infoText}>
                          Instagram
                        </Typography>
                      </Box>
                    </a>
                  </Link>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Box textAlign="center" mt={10}>
          <Typography variant="body2" className={classes.title}>
            COPYRIGHT &#169; {currentYear} UTILITIES ONE
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default DesktopFooter;
