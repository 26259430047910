import { FC, useEffect, useState } from 'react';
import { Box, Drawer, Grid, IconButton, makeStyles, Theme, Typography, withStyles } from '@material-ui/core';
import clsx from 'clsx';
import { NextRouter, useRouter } from 'next/dist/client/router';
import Link from 'next/link';
import { TLinks } from 'containers/layout/types';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { CloseIcon, LogoU1 } from 'svgs';
import { routes } from 'config/routes/routes';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiIconButton-root': {
      color: 'transparent',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
  burgerMenu: {
    width: 24,
    cursor: 'pointer',
    marginLeft: theme.spacing(0.5),
  },
  burgerLine: {
    height: 2,
    backgroundColor: theme.palette.primary.main,
    transition: 'background-color 400ms',
    marginBottom: theme.spacing(0.7),
  },
  activeLink: {
    color: theme.palette.common.white,
    fontSize: 24,
  },
}));

const StyledDrawer = withStyles((theme: Theme) => ({
  paper: {
    width: '100%',
    maxWidth: 331,
    padding: theme.spacing(7, 6),
    backgroundColor: theme.palette.background.paper,
    color: '#ffffff80',
    position: 'relative',
    '& svg': {
      position: 'absolute',
      right: theme.spacing(4),
      top: theme.spacing(4),
      cursor: 'pointer',
    },
  },
}))(Drawer);

const MobileNavbar: FC = () => {
  const [open, setOpen] = useState(false);
  const { asPath }: NextRouter = useRouter();
  const classes = useStyles();
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  useEffect(handleClose, [asPath]);

  return (
    <>
      <Box py={3.5} display="flex" className={classes.root}>
        <IconButton onClick={handleOpen}>
          <BurgerMenu classes={classes} />
        </IconButton>
        <Grid container direction="row" justify="center">
          <Grid item>
            <Link href="/">
              <Box ml={-9}>
                <LogoU1 />
              </Box>
            </Link>
          </Grid>
        </Grid>
      </Box>
      <StyledDrawer anchor="left" open={open} onClose={handleClose}>
        <CloseIcon onClick={handleClose} />
        {routes.map(({ label, route }, index) => (
          <Box key={index}>
            <NavLink classes={classes} label={label} route={route} active={asPath === route} />
          </Box>
        ))}
      </StyledDrawer>
    </>
  );
};

interface NavLinkProps extends TLinks {
  active: boolean;
  classes: ClassNameMap<string>;
}

const NavLink: FC<NavLinkProps> = ({ active, label, route, classes }) => (
  <Box mb={4}>
    <Link href={route} passHref>
      <Typography component="span" className={clsx(active && classes.activeLink)}>
        {label}
      </Typography>
    </Link>
  </Box>
);

const BurgerMenu: FC<{ classes: ClassNameMap<string> }> = ({ classes: { burgerLine, burgerMenu } }) => {
  return (
    <Box className={burgerMenu}>
      <Box className={burgerLine} />
      <Box className={burgerLine} />
      <Box style={{ marginBottom: 0 }} className={burgerLine} />
    </Box>
  );
};

export default MobileNavbar;
