import React, { FC } from 'react';
import { Theme, useMediaQuery, useScrollTrigger } from '@material-ui/core';
import DesktopMenu from './DesktopMenu';
import MobileMenu from './MobileMenu';

const Navbar: FC = () => {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const scrollDown = useScrollTrigger();

  return <>{isDesktop ? <DesktopMenu scrollDown={scrollDown} /> : <MobileMenu />}</>;
};

export default Navbar;
