import React, { FC } from 'react';
import { Theme, useMediaQuery } from '@material-ui/core';
import DesktopFooter from './DesktopFooter';
import MobileFooter from './MobileFooter';

const Footer: FC = () => {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  return <>{isDesktop ? <DesktopFooter /> : <MobileFooter />}</>;
};

export default Footer;
